/** 
 * Application List Item Action
 */
.application-list-item-action {
  border: none !important;
  opacity: 0.8;
}
.application-list-item-action:hover {
  opacity: 1;
  background: rgba(27, 28, 29, 0.03) !important;
}
