/** 
 * Application Launcher Menu List
 */
.application-launcher-menu-list {
  width: 22em !important;
  padding: 0em !important;
  display: flex !important;
  flex-direction: column !important;
}
.application-launcher-menu-list .top.row {
  border-bottom: 1px solid rgba(152, 162, 179, 0.2) !important;
}
.application-launcher-menu-list .top.row .header {
  font-variant: small-caps;
  gap: 0.25em;
}
.application-launcher-menu-list .middle.row {
  flex: 1 1 0px !important;
  overflow: hidden auto !important;
  scroll-behavior: smooth !important;
  height: 20em !important;
  min-height: 20em !important;
}
.application-launcher-menu-list .bottom.row {
  border-top: 1px solid rgba(152, 162, 179, 0.2) !important;
}
.application-launcher-menu-list ::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.application-launcher-menu-list ::-webkit-scrollbar-thumb {
  background: none !important;
}
.application-launcher-menu-list ::-webkit-scrollbar-track {
  background: none !important;
}
