/** 
 * Application Launcher List Item
 */
.application-launcher-list-item {
  padding: 0.8em 0em 0.8em 0.4em !important;
}
.application-launcher-list-item .accordion .title,
.application-launcher-list-item .accordion .content {
  padding: 0.1em !important;
}
.application-launcher-list-item .popup {
  padding: 0em !important;
}
.application-launcher-list-item .button {
  display: grid !important;
  grid-template-columns: 1fr 48px;
  column-gap: 1em !important;
  height: 68px !important;
  width: 180px !important;
  padding: 12px 4px !important;
  color: #ffffff !important;
  background: var(--current-color, var(--accent)) linear-gradient(180deg, rgba(255, 255, 255, 0.2), transparent 80%) !important;
}
.application-launcher-list-item .button > span {
  grid-column: 1;
  grid-row: 1;
  line-height: 1.2em;
  text-align: left;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: auto;
  margin-left: 4px;
}
.application-launcher-list-item .button > aside {
  grid-column: 2;
  grid-row: 1;
  font-size: 2.6em;
  font-weight: 100;
  opacity: 0.5;
  text-align: right;
}
.application-launcher-list-item .button > aside > .icon {
  margin-top: -6px;
  font-size: 28px;
  opacity: 0.5;
}
