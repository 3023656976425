/** 
 * Application List
 */
.application-list {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  padding: 0em !important;
  margin: 0em !important;
}
.application-list.placeholder {
  display: flex !important;
  opacity: 0.25 !important;
}
.application-list.virtualized {
  position: relative !important;
  overflow-y: scroll !important;
}
.application-list > .fitted.divider {
  margin: 0em 1em;
}
