/** 
 * Application Error
 */
.application-error {
  height: 100%;
  width: 100%;
  position: absolute;
}
.application-error .container {
  margin: auto !important;
  width: 44em !important;
}
.application-error .message {
  align-items: flex-start !important;
}
.application-error .header {
  margin-top: 0.5em !important;
}
.application-error .error .icon {
  color: #912d2b;
}
.application-error .info .icon {
  color: #0e566c;
}
.application-error .content {
  text-align: justify;
}
.application-error span {
  white-space: break-spaces;
}
.application-error p {
  color: rgba(27, 28, 29, 0.87);
}
