/** 
 * Application Menu Item
 */
.application-menu-item {
  flex: 0 0 0px !important;
  border-left: 4px solid transparent;
  border-radius: 0 !important;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  padding-right: 0.5em !important;
  align-items: center !important;
  display: flex !important;
  height: 40px;
  min-height: 40px;
  transition: background 150ms ease;
}
.application-menu-item > .icon {
  margin-left: 1em;
  font-size: 1.25em;
  padding: 0 !important;
}
.application-menu-item > svg {
  font-size: 1.25em;
}
.application-menu-item .content {
  flex: 1 !important;
  padding-left: 2em !important;
  font-size: 1em !important;
  transition: color 150ms ease !important;
}
.application-menu-item > .icon,
.application-menu-item > .content {
  color: inherit !important;
  min-width: auto !important;
}
.application-menu-item.disabled {
  color: rgba(27, 28, 29, 0.3) !important;
}
.application-menu-item + *:not(.application-menu-item):not(.application-menu-action) {
  overflow: auto!important;
  max-height: 0%;
  opacity: 0;
  transition: max-height 250ms cubic-bezier(0.694, 0.0482, 0.335, 1), opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.application-menu-item + *:not(.application-menu-item):not(.application-menu-action).selected {
  max-Height: 100%;
  opacity: 1;
}
.application-menu .ui.selection.list .application-menu-item,
.application-menu .ui.selection.list .application-menu-item ~ .selected .application-menu-item,
.application-menu .ui.selection.list .application-menu-item ~ .selected .application-list-item {
  transition: none !important;
  color: rgba(27, 28, 29, 0.6);
}
.application-menu .ui.selection.list .application-menu-item:hover,
.application-menu .ui.selection.list .application-menu-item ~ .selected .application-menu-item:hover,
.application-menu .ui.selection.list .application-menu-item ~ .selected .application-list-item:hover {
  background-color: transparent;
  background: var(--current-color, var(--accent)) linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) !important;
  color: var(--current-color, var(--accent)) !important;
}
.application-menu .ui.selection.list .application-menu-item.active {
  background-color: transparent;
  background: var(--current-color, var(--accent)) linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) !important;
  border-left-color: var(--current-color, var(--accent)) !important;
  color: var(--current-color, var(--accent)) !important;
}
.application-menu-item ~ .selected .application-list-item {
  border-radius: 0em !important;
  height: 30px !important;
}
.application-menu-item ~ .selected .application-list-item .content {
  font-size: 1rem !important;
}
