/** 
 * Application Container
 */
.application-container {
  height: 100%;
  width: 100%;
}
.application-container.ui.basic.segment {
  background: hsl(212, 60%, 95%);
  position: static;
}
.application-container > .segment {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
