/** 
 * Application Launcher List
 */
.application-launcher-list {
  background: #006594 !important;
  overflow: hidden;
}
.application-launcher-list .menu {
  display: flex !important;
  margin: 1em 0em !important;
  background: rgba(255, 255, 255, 0.15) !important;
  overflow-x: auto;
}
