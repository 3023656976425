/** 
 * Application Menu Action
 */
.application-menu .list .application-menu-action.item {
  cursor: pointer;
  margin-top: auto !important;
  min-height: 60px;
  opacity: 1 !important;
  padding: 0.5em !important;
  padding-left: 0.55em !important;
  align-items: center !important;
  color: rgba(27, 28, 29, 0.6);
}
.application-menu .list .application-menu-action.item > .icon {
  color: inherit !important;
  transition: none !important;
}
.application-menu .list .application-menu-action.item:hover {
  background: none !important;
}
.application-menu .list .application-menu-action.item.open {
  color: rgba(255, 255, 255, 0.9) !important;
}
.application-menu .list .application-menu-action.item.open .icon {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.application-menu .list .application-menu-action.item .icon {
  margin-top: 0.15em;
  border: none !important;
  box-shadow: none !important;
}
.application-menu-item + .application-menu .list .application-menu-action.item {
  overflow: hidden !important;
}
.application-menu .list .application-menu-action.open .icon {
  background: var(--current-color, var(--accent)) linear-gradient(180deg, rgba(255, 255, 255, 0.2), transparent 80%) !important;
  color: #ffffff;
}
.application-menu .list .application-menu-action.open .icon::before {
  content: "\f067";
}
