/** 
 * Application Menu
 */
.application-menu {
  display: flex;
  flex-direction: column;
  border-top-width: 0px !important;
  padding: 0 !important;
  transform: translate3d(0, 0, 0) !important;
  transition: ease 0.5s width, background 150ms ease !important;
}
.application-menu:not(.visible) {
  width: 58.52px !important;
  visibility: visible !important;
  box-shadow: none !important;
}
.application-menu ~ .pusher {
  width: calc(100% - 58.52px) !important;
  left: 58.52px !important;
  display: block;
}
.application-menu .list {
  flex-direction: column  !important;
  height: 100% !important;
  overflow: hidden !important;
  display: flex;
}
.application-menu hr {
  opacity: 1 !important;
  border-color: rgba(152, 162, 179, 0.1);
  margin-block: 1em;
  width: 100%;
}
