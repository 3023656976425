/** 
 * Application Launcher Bar
 */
.application-launcher-bar {
  height: 40px;
  transition: background 150ms ease;
}
.application-launcher-bar,
.application-launcher-bar ~ .pushable > .menu {
  background: #006594 !important;
}
.application-launcher-bar #brand {
  background: var(--current-color, var(--accent)) !important;
  padding-left: 2px !important;
  width: 160px;
}
.application-launcher-bar #brand .image {
  position: absolute;
  width: calc(100% - 40px) !important;
  right: -1em !important;
}
.application-launcher-bar #brand svg {
  left: 1.2em;
  position: absolute;
}
.application-launcher-bar #brand > .dev {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-weight: bold;
  background-color: #006594;
}
.application-launcher-bar #breadcrumb {
  color: rgba(255, 255, 255, 0.9) !important;
  flex-shrink: 0;
}
.application-launcher-bar #breadcrumb .breadcrumb {
  flex-shrink: 0;
}
.application-launcher-bar #breadcrumb .breadcrumb > .section:first-of-type {
  text-transform: uppercase;
}
.application-launcher-bar #breadcrumb .breadcrumb > .section {
  font-weight: initial;
  color: #ffffff;
}
.application-launcher-bar #breadcrumb .breadcrumb > .divider {
  color: rgba(255, 255, 255, 0.8);
}
.application-launcher-bar #breadcrumb.active {
  background: transparent !important;
}
.application-launcher-bar #breadcrumb i.launcher {
  content: url('../../assets/images/launcher.png');
  vertical-align: initial;
}
.application-launcher-bar #notification .label {
  height: 1em;
  width: 1em;
  top: 1.42857143em;
  left: 0.78571429em;
  bottom: -0.78571429em;
}
.application-launcher-bar .options {
  flex-grow: 1;
  justify-content: flex-end;
}
.application-launcher-bar .search {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;
  margin-left: 40px;
  flex: 1;
}
.application-launcher-bar .menu .item {
  padding: 0em 0.75em;
}
.application-launcher-bar .menu:not(.left):not(.right) {
  flex: 1 0 0px !important;
}
.application-launcher-bar .menu:not(.left):not(.right) > *,
.application-launcher-bar .menu:not(.left):not(.right) .centered {
  width: 100% !important;
}
