/** 
 * Application Menu Search
 */
.application-menu-search {
  width: 19.5em;
  font-size: 0.8em !important;
}
.application-menu-search > input {
  border: none !important;
  font-size: 1.2em;
}
.application-menu-item:hover .application-menu-search input::placeholder {
  color: var(--current-color, var(--accent)) !important;
}
.application-menu-item:hover .application-menu-search input::selection {
  background: var(--current-color, var(--accent)) linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) !important;
  color: #ffffff !important;
}
