/** 
 * Application Notification
 */
.application-notification {
  width: 25em !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}
.application-notification:not(.visible):not(.animating) {
  width: 0 !important;
}
.application-notification.visible.push ~ .pusher {
  width: calc(100% - 25em) !important;
  right: 25em !important;
}
.application-notification ~ .pusher {
  left: 0;
  right: 0;
  width: 100%;
  transform: none !important;
  transition: left 0.5s ease, right 0.5s ease, width 0.5s ease !important;
}
.application-notification > .container > .menu {
  position: fixed;
  margin-top: 0.55em;
  left: 0.55em;
  height: 1.6rem;
  min-height: 1rem;
  width: 100%;
}
.application-notification > .container > .menu > .right.menu > .button {
  padding: 0em 1.5em;
  margin-right: 0.5em;
}
.application-notification > .container > .container {
  margin: 2.8em 0em 0em 0em;
  height: calc(100% - 40px);
  flex-direction: column;
}
.application-notification > .container > .container > .stretched {
  flex: 1;
}
.application-notification .application-list {
  overflow: auto;
}
