/** 
 * Application Loader
 */
.application-loader {
  display: flex;
  text-align: left !important;
  margin: auto;
  height: 100%;
  width: 100%;
  align-self: flex-start;
  z-index: 1000;
}
.application-loader > .content {
  flex-direction: column !important;
}
.application-loader > .header {
  max-height: 12em;
}
.application-loader > .header .company {
  --element-width: 260px;
  content: url('../../assets/images/brand/company.png');
  width: var(--element-width);
  margin-right: calc(var(--element-width) / 3);
}
.application-loader > .header .company + span {
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  width: 160px;
  align-self: center;
  margin-left: auto;
}
.application-loader > .header .identity {
  content: url('../../assets/images/brand/identity.png');
  height: fit-content !important;
}
.application-loader > .header .banner {
  content: url('../../assets/images/brand/banner.png');
  width: auto !important;
  max-width: none !important;
}
.application-loader .logo {
  align-self: center;
  width: auto !important;
  max-height: 14em;
}
.application-loader.overlay {
  position: absolute;
  background: #ffffff;
}
.application-loader.screen {
  background: #ffffff;
}
.application-loader.screen > .content {
  align-self: flex-start;
}
.application-loader.screen > .row {
  align-self: flex-start;
}
.application-loader:not(.screen) {
  justify-content: center;
  align-items: center !important;
}
.application-loader .indicator {
  margin-top: 4em;
}
.application-loader .indicator > .message {
  display: block;
  margin-top: 1em;
}
.application-loader.dots,
.application-loader .dots {
  height: 1em;
}
.application-loader.dots > .dot,
.application-loader .dots > .dot {
  position: relative;
  width: 0.8em;
  height: 0.8em;
  margin: 0.4em;
  border-radius: 50%;
}
.application-loader.dots > .dot:before,
.application-loader .dots > .dot:before,
.application-loader.dots > .dot:after,
.application-loader .dots > .dot:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: inherit;
}
.application-loader.dots > .dot:before,
.application-loader .dots > .dot:before {
  animation: PulseLarge 2.5s ease-out infinite;
}
.application-loader.dots > .dot:after,
.application-loader .dots > .dot:after {
  animation: PulseSmall 2.5s ease-out infinite;
}
.application-loader.dots > .dot:first-child,
.application-loader .dots > .dot:first-child {
  background: #96c11f;
}
.application-loader.dots > .dot:first-child:before,
.application-loader .dots > .dot:first-child:before,
.application-loader.dots > .dot:first-child:after,
.application-loader .dots > .dot:first-child:after {
  box-shadow: 0 0 1px 1px #96c11f;
}
.application-loader.dots > .dot:first-child:before,
.application-loader .dots > .dot:first-child:before {
  animation-delay: 0.25s;
}
.application-loader.dots > .dot:first-child:after,
.application-loader .dots > .dot:first-child:after {
  animation-delay: 0.5s;
}
.application-loader.dots > .dot:nth-child(2),
.application-loader .dots > .dot:nth-child(2) {
  background: #971c82;
}
.application-loader.dots > .dot:nth-child(2):before,
.application-loader .dots > .dot:nth-child(2):before,
.application-loader.dots > .dot:nth-child(2):after,
.application-loader .dots > .dot:nth-child(2):after {
  box-shadow: 0 0 1px 1px #971c82;
}
.application-loader.dots > .dot:nth-child(2):before,
.application-loader .dots > .dot:nth-child(2):before {
  animation-delay: 0.5s;
}
.application-loader.dots > .dot:nth-child(2):after,
.application-loader .dots > .dot:nth-child(2):after {
  animation-delay: 0.75s;
}
.application-loader.dots > .dot:nth-child(3),
.application-loader .dots > .dot:nth-child(3) {
  background: #f07d19;
}
.application-loader.dots > .dot:nth-child(3):before,
.application-loader .dots > .dot:nth-child(3):before,
.application-loader.dots > .dot:nth-child(3):after,
.application-loader .dots > .dot:nth-child(3):after {
  box-shadow: 0 0 1px 1px #f07d19;
}
.application-loader.dots > .dot:nth-child(3):before,
.application-loader .dots > .dot:nth-child(3):before {
  animation-delay: 0.75s;
}
.application-loader.dots > .dot:nth-child(3):after,
.application-loader .dots > .dot:nth-child(3):after {
  animation-delay: 1s;
}
.application-loader.dots > .dot:nth-child(4),
.application-loader .dots > .dot:nth-child(4) {
  background: #c01622;
}
.application-loader.dots > .dot:nth-child(4):before,
.application-loader .dots > .dot:nth-child(4):before,
.application-loader.dots > .dot:nth-child(4):after,
.application-loader .dots > .dot:nth-child(4):after {
  box-shadow: 0 0 1px 1px #c01622;
}
.application-loader.dots > .dot:nth-child(4):before,
.application-loader .dots > .dot:nth-child(4):before {
  animation-delay: 1s;
}
.application-loader.dots > .dot:nth-child(4):after,
.application-loader .dots > .dot:nth-child(4):after {
  animation-delay: 1.25s;
}
.application-loader.dots > .dot:nth-child(5),
.application-loader .dots > .dot:nth-child(5) {
  background: #004a99;
}
.application-loader.dots > .dot:nth-child(5):before,
.application-loader .dots > .dot:nth-child(5):before,
.application-loader.dots > .dot:nth-child(5):after,
.application-loader .dots > .dot:nth-child(5):after {
  box-shadow: 0 0 1px 1px #004a99;
}
.application-loader.dots > .dot:nth-child(5):before,
.application-loader .dots > .dot:nth-child(5):before {
  animation-delay: 1.25s;
}
.application-loader.dots > .dot:nth-child(5):after,
.application-loader .dots > .dot:nth-child(5):after {
  animation-delay: 1.5s;
}
@keyframes PulseLarge {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes PulseSmall {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
