/** 
 * Application Help
 */
.application-help {
  width: 20em !important;
  padding: 0 !important;
  overflow-x: hidden !important;
  border-top: none;
}
.application-help:not(.visible):not(.animating) {
  width: 0 !important;
}
.application-help.visible.push ~ .pusher {
  width: calc(100% - 20em) !important;
  right: 20em !important;
}
.application-help ~ .pusher {
  left: 0;
  right: 0;
  width: 100%;
  transform: none !important;
  transition: left 0.5s ease, right 0.5s ease, width 0.5s ease !important;
}
.application-help > .container > .menu {
  position: fixed;
  margin-top: 0.55em;
  left: 0.55em;
  height: 1.6rem;
  min-height: 1rem;
  width: 100%;
}
.application-help > .container > .menu > .right.menu > .button {
  padding: 0em 1.5em;
  margin-right: 0.5em;
}
.application-help > .container > .container {
  margin: 2.8em 0em 0em 0em;
  height: calc(100% - 40px);
  flex-direction: column;
}
.application-help > .container > .container > .container {
  flex-direction: column;
}
.application-help > .container > .container > .stretched {
  flex: 1;
}
.application-help > .container > .container > .fitted {
  height: auto;
  margin-top: auto;
}
.application-help .application-list {
  overflow: auto;
}
.application-help .feedback {
  padding: 1em;
}
.application-help .module {
  padding: 1em;
  margin-top: auto !important;
  margin-left: auto !important;
}
.application-help .module .header {
  cursor: pointer;
}
.module.dependencies {
  padding: 0;
}
.module.dependencies.popup::before {
  background: hsl(210, 20%, 98%);
}
.__floater {
  transition: opacity 250ms !important;
}
