/** 
 * Application Search Layout
 */
.application-search-layout {
  min-height: 310px;
  max-height: 320px !important;
  margin: 0em !important;
}
.application-search-layout.application-search-layout.application-search-layout.application-search-layout.application-search-layout.application-search-layout .item {
  color: rgba(27, 28, 29, 0.87);
  transition: none !important;
}
