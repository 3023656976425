/** 
 * Application Menu Action Item
 */
.application-menu-action-item {
  cursor: pointer;
  opacity: 0;
  transform: scale(0.5);
  transition: transform 600ms cubic-bezier(0.075, 0.82, 0.165, 1), opacity 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.application-menu-action-item .icon {
  background: white !important;
  color: rgba(27, 28, 29, 0.87) !important;
  border: none !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.application-menu-action-item:hover .icon {
  background: #f2f2f2 !important;
}
.application-menu-action-item-transition-enter {
  opacity: 0;
  transform: scale(0.5);
}
.application-menu-action-item-transition-enter-active {
  opacity: 1;
  transform: scale(1);
}
.application-menu-action-item-transition-enter-done {
  opacity: 1;
  transform: scale(1);
}
.application-menu-action-item-transition-exit {
  opacity: 1;
  transform: scale(1);
}
.application-menu-action-item-transition-exit-active {
  opacity: 0;
  transform: scale(0.5);
}
