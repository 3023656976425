/** 
 * Application Search Result
 */
.application-search-category {
  border-radius: 0em !important;
  border: none !important;
  margin: 0em !important;
}
.application-search-category.header.block {
  background: var(--current-color, var(--accent)) !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
.application-search-category.header.block > .right.floated.header > .circular.label {
  background: var(--current-color, var(--accent)) linear-gradient(180deg, rgba(255, 255, 255, 0.2), transparent 80%) !important;
  color: rgba(255, 255, 255, 0.9) !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.active.category > .application-search-category.header.block {
  font-weight: bold !important;
}
