/** 
 * Application Launcher Popup
 */
.application-launcher-popup {
  border-top: 8px solid var(--current-color, var(--accent)) !important;
}
.application-launcher-popup::before {
  box-shadow: none !important;
  background: var(--current-color, var(--accent)) !important;
  top: -1em !important;
}
