/** 
 * Application Content
 */
.application-content {
  overflow: auto !important;
}
.application-content > .segment {
  margin-top: 0;
}
.application-content-content {
  background: hsl(212, 60%, 95%);
  color: rgba(27, 28, 29, 0.87);
  border-color: rgba(152, 162, 179, 0.2);
  height: 100%;
  flex: 1;
}
.application-content-content main > .ui.inverted.attached.segment {
  padding: 18px 14px;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: transparent;
  border: none;
}
.application-content-content main > .ui.inverted.attached.segment > .ui.segment {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid rgba(152, 162, 179, 0.2);
  border-radius: 0.28571429rem;
  background: #FFF !important;
  box-shadow: none !important;
}
.application-content-header {
  overflow: hidden !important;
}
.application-content-header .application-loader {
  display: none !important;
}
.application-content main {
  height: 100%;
  width: 100%;
}
