/** 
 * Application List Item
 */
.application-list-item {
  height: auto !important;
  padding: 0em !important;
  align-items: center !important;
  border-radius: 0 !important;
  background: transparent;
}
.ui.selection.list .application-list-item.item {
  color: rgba(27, 28, 29, 0.6);
  transition-property: color, padding-left;
}
.application-list-item > * {
  color: inherit !important;
}
.application-list-item .content {
  color: inherit !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  padding-right: 0.5em !important;
}
.application-list-item .content > * {
  overflow: inherit !important;
  text-overflow: inherit !important;
  padding: 0em !important;
}
.application-list-item .content.right {
  background: rgba(27, 28, 29, 0.03) !important;
  padding: 0em !important;
  margin: auto !important;
  height: 100%;
}
.application-list-item .content.right .buttons {
  border: none !important;
  width: 100% !important;
  justify-content: space-between !important;
}
.application-list-item .content.right .buttons.column {
  flex-flow: column wrap !important;
}
.application-list-item .content.right .buttons.row {
  flex-flow: row wrap !important;
}
.application-list-item .content.right .buttons > * {
  box-shadow: none !important;
  min-height: auto !important;
}
.application-list-item .content.placeholder {
  margin: 0.5em !important;
  padding: 0em !important;
  overflow: hidden !important;
}
.application-list-item .content.placeholder > * {
  overflow: unset !important;
}
.application-list-item .header {
  color: inherit !important;
}
.application-list-item .description {
  color: inherit !important;
  white-space: break-spaces !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}
.application-list-item:not(:hover) .right.content {
  visibility: collapse !important;
}
.application-list-item:not(:hover) .right.content .row {
  display: none !important;
}
.application-list-item:hover,
.application-list-item.active:hover,
.application-list-item.active:not(:hover) {
  color: var(--current-color, var(--accent)) !important;
  background: var(--current-color, var(--accent)) linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) !important;
}
.application-list-item.disabled .content {
  color: rgba(27, 28, 29, 0.3) !important;
}
.application-list-item .indicator {
  width: 8px;
  float: left;
  height: 100%;
  min-height: 30px;
}
.application-list-item .indicator.selected {
  border-left: 4px solid #006594;
}
.application-list-item .indicator.selected .pushable {
  overflow: visible;
}
.application-list-item .indicator.selected .pushable .sidebar {
  width: 4px;
  background-color: #006594;
  transition: transform 150ms ease;
}
.application-list-item .indicator.selected:hover .sidebar {
  visibility: visible;
  transform: translateZ(0);
}
.application-list-item .indicator.selected {
  border-left-color: var(--current-color, var(--accent));
}
.application-list-item .indicator.selected .pushable {
  overflow: visible;
}
.application-list-item .indicator.selected .pushable .sidebar {
  background: var(--current-color, var(--accent)) !important;
}
.application-list-item > svg {
  width: 1.18em;
  height: 1em;
  font-size: 1.25em;
}
