/** 
 * Application Search
 */
.ui.search.application-search {
  display: flex;
  justify-content: flex-end;
}
.ui.search.application-search .transparent.input {
  background: #267ca4;
  border: 1px solid #267ca4;
  border-radius: 0.28571429rem;
  height: 32px;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  align-self: center;
  z-index: 2;
}
.ui.search.application-search .transparent.input:hover .button {
  color: #ffffff;
}
.ui.search.application-search .transparent.input:focus,
.ui.search.application-search .transparent.input.focus {
  border-bottom-color: #ffffff;
}
.ui.search.application-search .transparent.input:focus .button,
.ui.search.application-search .transparent.input.focus .button {
  color: #ffffff !important;
}
.ui.search.application-search .transparent.input > input {
  padding-left: 0.5em !important;
  min-width: 240px !important;
  max-width: 360px !important;
}
.ui.search.application-search .transparent.input > .dropdown {
  background: transparent;
  color: #ffffff;
  padding-right: 1.1em;
}
.ui.search.application-search .transparent.input > .dropdown:has(> .label) {
  padding-top: 0em;
  padding-right: 0em;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.ui.search.application-search .transparent.input > .dropdown > .label:first-of-type {
  margin-left: 0.55em;
  padding-right: 0.25em;
}
.ui.search.application-search .transparent.input > .dropdown > .label {
  background: transparent;
  color: #ffffff;
  opacity: 1;
  border: none;
  box-shadow: none;
  text-wrap: nowrap;
}
.ui.search.application-search .transparent.input:has(> .dropdown > .label) > .filter.icon {
  opacity: 1;
}
.ui.search.application-search .transparent.input > .dropdown:hover ~ .filter.icon {
  opacity: 0.8;
}
.ui.search.application-search .transparent.input > .dropdown ~ .filter.icon {
  left: 8px;
}
.ui.search.application-search:has(> .results):before {
  opacity: 0;
  top: 0px;
  right: 0px;
  content: '';
  overflow: hidden;
  transition: opacity 500ms;
  will-change: opacity;
  z-index: 1;
  display: block;
  position: fixed;
}
.ui.search.application-search:has(> .results.visible):not(:has(.message.empty)):before {
  background-color: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100%;
  opacity: 1;
}
.ui.search.application-search > .results.visible {
  display: flex !important;
  left: auto;
  right: 0%;
  width: 100vh;
  min-width: 100%;
  border: none;
}
.ui.search.application-search > .results.visible > .category {
  background: #ffffff !important;
  width: 100% !important;
  height: 100%;
  flex: 1;
}
.ui.search.application-search > .results.visible > .category:first-child {
  border-radius: 0.28571429rem 0em 0em 0.28571429rem;
}
.ui.search.application-search > .results.visible > .category:last-child {
  border-radius: 0em 0.28571429rem 0em 0.28571429rem;
}
.ui.search.application-search > .results.visible > .category .highlight > mark {
  background: transparent;
  color: var(--current-color, var(--accent));
  font-weight: bold;
}
.ui.search.application-search .message .header {
  color: rgba(27, 28, 29, 0.87) !important;
  font-weight: 700 !important;
}
.ui.search.application-search .placeholder-shortcut {
  margin-left: 1em;
  gap: 0.15rem;
  align-items: center;
  position: absolute;
  right: 4px;
  height: 100%;
  display: none;
}
.ui.search.application-search .placeholder-shortcut kbd {
  background: #006594;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 0.375em;
  border-width: 1px 1px 3px;
  font-size: 0.8em;
  font-weight: 700;
  line-height: normal;
  padding-inline: 0.4em;
  white-space: nowrap;
  align-self: center;
}
.ui.search.application-search .placeholder-shortcut:has(~ input:placeholder-shown) {
  display: inline-flex;
}
