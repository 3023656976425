/** 
 * Application Header
 */
.application-header {
  background: #f1fafe !important;
  transition: background 150ms ease;
}
.application-header.menu {
  min-height: unset !important;
  border: none !important;
}
.application-header.menu .item {
  font-size: inherit !important;
  padding: 0.75em;
}
.application-header.menu .item.disabled {
  opacity: 0.75 !important;
  color: rgba(27, 28, 29, 0.3) !important;
}
.application-header.menu .item > i.icon::before {
  font-size: 1.25rem !important;
  vertical-align: middle;
}
.application-header.menu .item.button.icon {
  flex-direction: row !important;
  padding: 0.75em 0.75em 0.75em 0.5em;
  gap: 0.5em;
}
.application-header.menu .item.button.icon:hover {
  background: rgba(27, 28, 29, 0.03);
}
.application-header.menu .item.button.icon:hover > i.icon {
  color: #006594 !important;
}
.application-header.menu .item.button.icon > i.icon:not(.dropdown):only-child {
  margin: auto auto auto 2px !important;
}
